.App {
  background-color: #f8f8f8;
}

.App .navbar-brand {
  color: #ffffff;
}

.placementfield-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 30px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.placementfield__row {
  display: flex;
}

.placementfield__item {
  outline: 1px solid rgba(0, 0, 0, 0.1);
  /* height: 12px;
  width: 12px; */
  line-height: 12px;
}
.placementfield__item_disabled {
  outline: none;
}

.btn-csvreader input {
}
